import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import cn from 'classnames';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { httpPost } from '../../../../utils/httpClient';
import styles from '../../Authentication.module.scss';
import { AuthInput } from '../AuthInput/AuthInput';
import { useSelector } from 'react-redux';

export const PasswordRecovery = ({ changeState, darkMode = false }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const userStore = useSelector((state) => state.user.value);
  const resetPassword = (values) => {
    setUserEmail(values.user);
    setLoading(true);
    httpPost(`/api/user/recoveryEmail`, { email: values.user }).then((res) => {
      if (res.ok) {
        setSuccess(true);
        setErrorMessage(null);
      } else {
        if (
          userStore.country === 'NO' ||
          userStore.country === 'SE' ||
          userStore.country === 'DK'
        ) {
          setErrorMessage('This email is not registered on BusinessClass.com or other error happened');
        } else {
          setErrorMessage('This email is not registered on BusinessClass.com');
        }
      }
      setLoading(false);
    });
  };
  return (
    <div className={darkMode ? styles.authDarkTheme : ''}>
      <Formik
        initialValues={{
          user: '',
        }}
        onSubmit={(values) => {
          resetPassword(values);
        }}
        className="flex flex-col items-center"
      >
        {() => (
          <Form>
            {!success && (
              <span className={cn(styles.header, 'text-center my-4 font-fragment')}>
                Forgot Password
              </span>
            )}
            {success && (
              <span className={cn(styles.header, 'text-center my-4 font-fragment')}>
                Check your email
              </span>
            )}
            {!success && (
              <div className={cn(styles.fieldInfo)}>
                <p>
                  Please enter email which you have been using for
                  BusinessClass.com.
                </p>
              </div>
            )}
            {success && (
              <div className={cn(styles.fieldInfo)}>
                <p>We’ve sent you an email to <b>{userEmail}</b> to reset your password.</p>
                <p>If you don’t see an email from us within a few minutes, please check your spam folder and the email address you provided.
                </p>
                <p className='mt-4'>
                  If the email has not arrived, please try again to <a onClick={() => setSuccess(false)} className='cursor-pointer'>reset password</a>
                </p>

              </div>
            )}
            <div className="max-w-sm flex flex-col items-center mx-auto">
              {!success && <AuthInput name="user" label="Email" />}
              {loading && <div className={styles.loader} />}
              {errorMessage && (
                <div className={styles.error}>
                  <IconButton
                    color="inherit"
                    onClick={() => setErrorMessage(null)}
                    aria-label="close"
                    className={styles.closeDialog}
                  >
                    <CloseIcon />
                  </IconButton>
                  <span>{errorMessage}</span>
                </div>
              )}
              {!success && (
                <button
                  className={cn(
                    styles.loginButton,
                    'text-white font-serif mx-auto'
                  )}
                >
                  Reset password
                </button>
              )}
              {!success && (
                <span
                  onClick={() => changeState('login')}
                  className={styles.forgotPassword}
                >
                  Cancel
                </span>
              )}
              {success && (
                <span
                  onClick={() => changeState('login')}
                  className={styles.forgotPassword}
                >
                  Back to login
                </span>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
