import cn from 'classnames';
import {object, string, ref} from 'yup';
import { ErrorMessage, Form, Formik } from 'formik';
import React, { useState } from 'react';
// import { useRouter } from 'next/router';
// import TagManager from 'react-gtm-module';
// import { useSelector } from 'react-redux';
import { httpPost } from '../../../../utils/httpClient';
import styles from '../../Authentication.module.scss';
import { AuthInput } from '../AuthInput/AuthInput';

const validationSchema = object().shape({
  password: string()
    .required('Password is required'),
  passwordRepeat: string()
    .oneOf([ref('password'), null], 'Passwords must match')
    .required('Repeat password is required'),
});

export const SetPassword = ({ props, authCompleted, changeState, darkMode = false }) => {
  const [loading, setLoading] = useState(false);
  // const userStore = useSelector((state) => state.user?.value);
  // const router = useRouter();
  const setPassword = (value) => {

    setLoading(true);
    httpPost('/api/user/reset', { password: value.password }).
      then((resolve) => {
        if (resolve.ok) {
          setLoading(false);
          authCompleted();
          changeState('setAirport');
          // const tagManagerArgs = {
          //   dataLayer: {
          //     event: 'accountCreated',
          //     userCountry: userStore?.country.toUpperCase(),
          //     pageType: router?.pathname,
          //     membershipId: userStore?.membershipNumber,
          //     signupSource: sourcePage
          //   },
          // };
          // TagManager.dataLayer(tagManagerArgs);
        }
      });
  };
  return (
    <div className={darkMode ? styles.authDarkTheme : ''}>
      <Formik
        initialValues={{
          passwordRepeat: '',
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setPassword(values);
        }}
        className="flex flex-col items-center"
      >
        {() => (
          <Form>
            <div {...props} className="flex flex-col items-center">
              <span className={cn(styles.header, 'text-center my-4 font-fragment')}>
                Set your password
              </span>
              <div className="max-w-sm flex flex-col items-center">
                <AuthInput name="password" type="password" label="Password"/>
                <ErrorMessage name="password" component="div" className={cn(styles.inputErrorMessage, 'text-left')} />
                <AuthInput
                  name="passwordRepeat"
                  type="password"
                  label="Repeat password"
                />
                <ErrorMessage name="passwordRepeat" component="div" className={cn(styles.inputErrorMessage, 'text-left')} />
                {loading && <div className={styles.loader}/>}
                <button
                  type='submit'
                  className={cn(
                    styles.loginButton,
                    'text-white font-serif mx-auto',
                  )}
                >
                  Continue
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
