import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Field } from 'formik';
import styles from '../../Authentication.module.scss';

export const AuthInput = ({
  type,
  name,
  label,
  onClick,
  options = [],
  placeholder = '',
  errorMessage,
}) => {
  useEffect(() => {
    if (errorMessage) {
      setErrorState(true);
    }
  }, [errorMessage]);
  const [errorState, setErrorState] = useState(false);
  return (
    <label className="w-full" onClick={onClick}>
      <span className={cn(styles.label)}>{label}</span>
      {type === 'select' ? (
        <Field
          placeholder={placeholder}
          as="select"
          name={name}
          className={cn(styles.input)}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value.toUpperCase()}>
              {option.label}
            </option>
          ))}
        </Field>
      ) : (
        <Field
          placeholder={placeholder}
          onInput={() => setErrorState(false)}
          name={name}
          className={cn(styles.input, {
            [styles.inputError]: errorState,
          })}
          type={type}
        />
      )}
      {errorState && (
        <span className={cn(styles.inputErrorMessage)}>{errorMessage}</span>
      )}
    </label>
  );
};
